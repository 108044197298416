<template>
  <div class="w-full">
    <div
      class="w-full h-screen bg-cover bg-center flex flex-col items-center justify-center relative home-background"
      :style="`background-image: url(${require(`@/assets/backgrounds/bg-1.jpg`)});`"
    >
      <div class="w-1/2 lg:w-1/3 max-w-md px-4 hidden sm:block">
        <img class="w-full" src="@/assets/logo/logo_transparent.svg" alt="Logo Koken met Kat" />
      </div>

      <div class="mt-8 px-16">
        <div class="text-center w-full max-w-sm mx-auto font-light">
          Studente met een passie voor koken die graag haar receptjes met de wereld deelt. Enjoy!
        </div>
        <div class="text-center font-light mt-2">
          Find me on Instagram <a class="font-medium" target="_blank" href="https://www.instagram.com/kokenmetkat/">@kokenmetkat</a>
        </div>
      </div>

      <div class="absolute bottom-0 left-0 right-0 w-full flex flex-col items-center">
        <font-awesome-icon icon="chevron-down" class="text-4xl mb-2" />
        <div
          class="w-full bg-gradient-to-b from-transparent to-secondary"
          style="height: calc(var(--vh, 1vh) * 10);"
        >

        </div>
      </div>
    </div>
    <div class="mt-4 px-6 sm:px-24 md:px-12 lg:px-24 flex flex-col items-center max-w-6xl mx-auto">
      <div>
        <h2 class="text-center text-3xl font-written mt-8">Nieuwe recepten</h2>
        <div class="flex flex-wrap">
          <RecipeTile
            v-for="recipe in recipes" :key="recipe.id"
            :recipe="recipe"
            class="w-1/2 md:w-1/4"
          />
        </div>
        <div class="mb-10 mt-10 flex justify-around">
          <router-link to="/recepten" class="bg-primary text-ternary rounded-lg p-2 text-center hover:underline">Bekijk recepten</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.home-background {
  height: calc(var(--vh, 1vh) * 110);
}
</style>

<script>
import RecipeTile from '@/components/RecipeTile2'
import http from '../http'

export default {
  name: 'Home',
  components: {
    RecipeTile
  },
  data () {
    return {
      recipes: [],
      heightHelper: null,
      interval: null
    }
  },
  mounted () {
    this.getRecipes()

    window.addEventListener('resize', this.resize)

    this.interval = setInterval(() => {
      this.resize()
    }, 500)

    this.resize()
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      if (this.heightHelper && this.heightHelper < 10) {
        clearInterval(this.interval)
        return
      }

      this.heightHelper = window.innerHeight * 0.01

      document.documentElement.style.setProperty('--vh', `${this.heightHelper}px`)
    },
    async getRecipes () {
      const res = await http.get('/api/public/recipes')

      this.recipes = res.data.slice(0, 4)
    }
  }
}
</script>
